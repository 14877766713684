import React from "react";
import { Button, Col, Row } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import * as queryString from "query-string";

import { calcDiscount, createHotelRoomsTextsArray, getCurrencyBadge } from "../../helpers/common";
import { LISTING_TYPE_B2B, MODAL_TYPE_CONFIRM } from "../constants";
import { showModalAction } from "../../redux/general/generalActions";
import { cancelBookingRequest, clearListingErrorAction } from "../../redux/listing/listingActions";
import CancellationPaymentMessage from "../shared/CancellationPaymentMessage";

export const ListingDetails = ({
  listing,
  currency,
  isProcessing,
  error,
  renderButton,
  showModal,
  cancelBooking,
  clearError
}) => {
  const location = useLocation();
  const { accessToken } = queryString.parse(location.search, { arrayFormat: "bracket" });

  const hotelAddress = listing.Hotel && listing.Hotel.HotelAddress ? listing.Hotel.HotelAddress : {};
  const currencyBadge = getCurrencyBadge(listing.Seller ? listing.Seller.currency : (listing.currency || currency));
  const discount = calcDiscount(listing.sellingPrice, listing.originalPrice);

  const isRefundable = listing?.additionalDetails?.isRefundable;
  const guaranteeType = listing?.additionalDetails?.guarantee?.type;
  const depositPolicy = listing?.additionalDetails?.depositPolicy;
  const cancellationPolicy = listing?.additionalDetails?.cancellationPolicy;

  return (
    <Row>
      <Col span={24} className="form-container listing-details">
        <Row className="listing-details__row">
          <Col span={24} className="listing-details__title">
            Details
          </Col>
        </Row>
        <Row gutter={8} className="listing-details__row">
          <Col span={12}>
            <div className="listing-details__date">
              <div className="listing-details__label">Check-In</div>
              <div className="listing-details__date-content">
                <img src="/images/icons/calendar-check.svg" alt="calendar"/>
                {moment(listing.checkInDate).format("DD MMM YYYY")}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="listing-details__date">
              <div className="listing-details__label">Check-Out</div>
              <div className="listing-details__date-content">
                <img src="/images/icons/calendar-check.svg" alt="calendar"/>
                {moment(listing.checkOutDate).format("DD MMM YYYY")}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="listing-details__row">
          <Col span={24}>
            <div className="listing-details__label">Guests</div>
            <div>
              {
                [
                  ...createHotelRoomsTextsArray(listing.rooms, listing.adults, listing.children),
                  ...(listing.facilities || []),
                  (listing.facilitiesOther ? listing.facilitiesOther : null),
                  (listing.type === "organic" ? listing.roomType !== "other" ? listing.roomType : null : null),
                  (listing.roomTypeOther ? listing.roomTypeOther : null)
                ].filter(item => !!item).join(" • ")
              }
            </div>
          </Col>
        </Row>
        {/*{ listing?.additionalDetails?.detailedRoomDescription &&*/}
        {/*  <Row className="listing-details__row">*/}
        {/*    <Col span={24}>*/}
        {/*      {listing?.additionalDetails?.detailedRoomDescription}*/}
        {/*    </Col>*/}
        {/*  </Row>*/}
        {/*}*/}
        <Row className="listing-details__row">
          <Col span={24}>
            <div className="listing-details__label">Address</div>
            <div>
              {hotelAddress.countryName },&nbsp;
              {hotelAddress.cityName},&nbsp;
              {hotelAddress.addressLine},&nbsp;
              {hotelAddress.postalCode}
            </div>
          </Col>
        </Row>
        { listing.originalPrice > 0 &&
        <Row className="listing-details__row">
          <Col span={24}>
            <div className="listing-details__label">Original Price</div>
            <div className="listing-details__original-price-container">
              <div className="listing-details__original-price">
                <img src="/images/money-bill-alt.svg" alt="money"/>
                <span>{`${currencyBadge} ${(+listing.originalPrice).toFixed(2)}`}</span>
              </div>
              { discount > 0 &&
              <div className="listing-details__discount">-{discount}% off</div>
              }
            </div>
          </Col>
        </Row>
        }
        {!!listing.reasonOfSale &&
          <Row className="listing-details__row">
            <Col span={24}>
              <div className="listing-details__label">Reason for Resale</div>
              <div>
                {listing.reasonOfSale}
              </div>
            </Col>
          </Row>
        }
        <Row className="listing-details__row">
          <Col span={24}>
            <Row gutter={8}>
              {renderButton()}
            </Row>
          </Col>
        </Row>
        {
          listing.type === LISTING_TYPE_B2B &&
          ["canceled"].includes(listing.status) &&
          listing.additionalDetails &&
          listing.additionalDetails.cancellationConfirmationNumber &&
          <Row className="listing-details__row">
            <Col span={24}>
              <div className="listing-details__label">Cancellation confirmation number</div>
              <div>
                {listing.additionalDetails.cancellationConfirmationNumber}
              </div>
            </Col>
          </Row>
        }
        { listing.type === LISTING_TYPE_B2B && !["canceled", "suspended"].includes(listing.status) &&
          <React.Fragment>
            <CancellationPaymentMessage
              isRefundable={isRefundable}
              guaranteeType={guaranteeType}
              depositPolicy={depositPolicy}
              cancellationPolicy={cancellationPolicy}
            />
            { listing.status === "sold" && listing.canBeCancelled &&
              <Row>
                <Col span={24} className="listing-view__cancel-order-container">
                  <Button
                    type="danger"
                    className="listing-view__cancel-order"
                    onClick={() => {
                      clearError();

                      showModal({
                        type: MODAL_TYPE_CONFIRM,
                        title: "Are You sure you would like to cancel booking?",
                        width: 500,
                        params: {
                          icon: "/images/info.svg",
                          confirmButtonText: "Yes",
                          showLoader: true,
                          isProcessing,
                          error,
                          confirmAction: () => cancelBooking({
                            id: listing.id,
                            slug: listing.slug,
                            accessToken
                          })
                        }
                      })
                    }}
                  >
                    Cancel Booking
                  </Button>
                </Col>
              </Row>
            }
          </React.Fragment>
        }
      </Col>
    </Row>
  );
};

ListingDetails.propTypes = {
  listing: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  renderButton: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  cancelBooking: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired
};


export default connect(
  state => ({
    currency: state.profile.permissions.currency,
    isProcessing: state.listing.processing,
    error: state.listing.error
  }),
  {
    showModal: showModalAction,
    cancelBooking: cancelBookingRequest,
    clearError: clearListingErrorAction
  }
)(ListingDetails);
