import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { Menu, Dropdown, Row, Col, Drawer, Grid } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import * as queryString from "query-string";

import { signOutAction, clearAuthErrorAction, clearVerifyAccountStatusAction } from "../../redux/auth/authActions";
import { setNewListingData } from "../../redux/listing/listingActions";
import { ADMIN_ROLE, SUPER_ADMIN_ROLE } from "../constants";
import useSingleton from "../../hooks/useSingleton";
import ListingSearch from "./ListingSearch";

let initialQuery = null;

const setListingsSearchQuery = (query) => {
  if (window && window.localStorage) {
    window.localStorage.setItem("searchQuery", query);
  }
};

const Header = ({
  isAuthenticated,
  user,
  userRole,
  history,
  page,
  signOut,
  setNewListing,
  clearAuthError,
  clearVerifyAccountStatus
}) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { md, lg } = Grid.useBreakpoint();

  const isTabletView = md && !lg;

  useSingleton(() => {
    try {
      setListingsSearchQuery(history.location.search);
      initialQuery = queryString.parse(history.location.search.slice(1), { arrayFormat: "bracket" }) || {};
    } catch (e) {
      initialQuery = {};
    }
  });

  const closeMenu = () => {
    setShowMobileMenu(false)
  };

  const sellReservationHandler = () => {
    setShowMobileMenu(false);

    if (isAuthenticated) {
      setNewListing({});
    }

    history.push("/selling");
  };

  const searchListings = (data) => {
    if (!Object.values(data).find(b => b)) {
      return;
    }

    const urlQuery = queryString.stringify(data, {
      skipEmptyString: true,
      skipNull: true,
      arrayFormat: "bracket"
    });

    history.push(`/listings?${urlQuery}`);
  };

  const showSearch = ["/listings"].includes(history.location.pathname);

  return (
    <Row justify="space-around" align="middle" className={`header${page ? ` header--${page}` : ""}`}>
      <Col xs={{ span: 24 }} md={{ span: 23 }}>
        <Row justify="space-around" align="middle">
          <Col xs={{ span: 22 }} sm={{ span: 6 }} xl={{ span: 5 }}>
            <Link to="/">
              <img className="header__logo" src="/images/logo.svg" alt="PlansChange" />
            </Link>
          </Col>
          { showSearch &&
            <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 10 }} className="header__search-container">
              <Row>
                <ListingSearch
                  showFilters={true}
                  showFiltersLabel={false}
                  initialValue={initialQuery}
                  onSearch={searchListings}
                />
              </Row>
            </Col>
          }
          <Col xs={{ span: 2 }} sm={{ span: 18 }} xl={{ span: showSearch ? 8 : 18 }}>
            <div className="header__mobile-menu">
              <img
                className="header__mobile-menu-sandwich"
                src="/images/icons/bars-solid.svg"
                alt="menu"
                onClick={() => setShowMobileMenu(!showMobileMenu)}
              />
              <Drawer
                placement="top"
                closable={true}
                onClose={() => setShowMobileMenu(false)}
                visible={showMobileMenu}
                height="100%"
                className="header__mobile-menu-drawer"
                bodyStyle={{
                  background: "#3D4CC1",
                  padding: "32px 32px 0 32px"
                }}
                headerStyle={{
                  background: "#3D4CC1",
                  border: "none",
                  padding: "32px",
                  height: "50px"
                }}
                drawerStyle={{
                  background: "#3D4CC1"
                }}
              >
                <Row className="header__mobile-drawer-content">
                  <Col xs={{ span: 24 }}>
                    <div className="header__mobile-menu-content">
                      {(isAuthenticated && user)
                        ? <React.Fragment>
                          <div>
                            <Link to="/profile" onClick={closeMenu} className="header__mobile-user">
                              <img
                                src="/images/icons/default_avatar-white.svg"
                                alt={`${user.firstName} ${user.lastName}`}
                                className="header__avatar"
                              />
                              <div className="header__user-name-container">
                                <div className="header__user-name">
                                  {`${user.firstName} ${user.lastName}`}
                                </div>
                                <div className="header__edit-profile">Edit profile</div>
                              </div>
                            </Link>
                          </div>
                          <div>
                            <Link to="/seller/wallet" className="header__user-link" onClick={closeMenu}>
                              <img
                                src="/images/icons/wallet-white.svg"
                                alt="Wallet"
                                className="header__user-link-icon"
                              />
                              <span>Wallet</span>
                            </Link>
                            <Link to="/profile/purchases/upcoming" className="header__user-link" onClick={closeMenu}>
                              <img
                                src="/images/icons/my-purchases-white.svg"
                                alt="My Purchases"
                                className="header__user-link-icon"/>
                              <span>My Purchases</span>
                            </Link>
                            <Link to="/seller/listings/active" className="header__user-link" onClick={closeMenu}>
                              <img
                                src="/images/icons/my-listings-white.svg"
                                alt="My Listings"
                                className="header__user-link-icon"
                              />
                              <span>My Listings</span>
                            </Link>
                            <Link to="/selling" className="header__user-link" onClick={closeMenu}>
                              <img
                                src="/images/icons/sell-your-booking-white.svg"
                                alt="Sell Your Booking"
                                className="header__user-link-icon"
                              />
                              <span>Sell Your Booking</span>
                            </Link>
                            <Link to="/faq" className="header__user-link" onClick={closeMenu}>
                              <img src="/images/icons/faqs-white.svg" alt="FAQs" className="header__user-link-icon"/>
                              <span>FAQs</span>
                            </Link>
                            <Link to="/contact-us" className="header__user-link" onClick={closeMenu}>
                              <img
                                src="/images/icons/contact-us-white.svg"
                                alt="Contact Us"
                                className="header__user-link-icon"
                              />
                              <span>Contact Us</span>
                            </Link>
                          </div>
                          <div onClick={() => {
                            closeMenu();
                            signOut();

                            history.push("/");
                          }} className="header__sign-out-link">Log Out
                          </div>
                        </React.Fragment>
                        : <div>
                          <Link
                            to="/auth/sign-up"
                            onClick={() => {
                              clearVerifyAccountStatus();
                              setShowMobileMenu(false);
                            }}
                            className="header__user-link"
                          >
                            Register
                          </Link>
                          <Link
                            to="/auth/sign-in"
                            onClick={closeMenu}
                            className="header__user-link"
                          >
                            Log In
                          </Link>
                          <Link
                            to="/seller/wallet"
                            onClick={closeMenu}
                            className="header__user-link"
                          >
                            Seller Account
                          </Link>
                          <Link
                            to="/legal/privacy-policy"
                            onClick={closeMenu}
                            className="header__user-link"
                          >
                            Privacy Policy
                          </Link>
                          <Link
                            to="/legal/terms-and-conditions"
                            onClick={closeMenu}
                            className="header__user-link"
                          >
                            Terms & Conditions
                          </Link>
                          <Link
                            to="/faq"
                            onClick={closeMenu}
                            className="header__user-link"
                          >
                            FAQs
                          </Link>
                          <Link
                            to="/about-us"
                            onClick={closeMenu}
                            className="header__user-link"
                          >
                            About Us
                          </Link>
                          <Link
                            to="/resale-deals"
                            onClick={closeMenu}
                            className="header__user-link"
                          >
                            Resale Deals
                          </Link>
                          <Link
                            to="/resale-events"
                            onClick={closeMenu}
                            className="header__user-link"
                          >
                            Resale Events
                          </Link>
                          <Link
                            to="/selling"
                            onClick={closeMenu}
                            className="header__user-link"
                          >
                            Selling: How It Works
                          </Link>
                          <Link
                            to="/contact-us"
                            onClick={closeMenu}
                            className="header__user-link"
                          >
                            Contact Us
                          </Link>
                          <Link
                            to="/blog"
                            onClick={closeMenu}
                            className="header__user-link"
                          >
                            Blog
                          </Link>
                        </div>
                      }
                    </div>
                  </Col>
                </Row>
              </Drawer>
            </div>
            <div className="header__links">
              <div className="header__link header__link--resell" onClick={sellReservationHandler}>
                <img src="/images/icons/create-listing.svg" alt="Resell My Room" className="header__link-icon"/>
                Resell Room
              </div>
              { !showSearch && !isTabletView &&
                <Link to="/about-us" className="header__link header__link--pointer">
                  <img src="/images/about-us.svg" alt="About Us" className="header__link-icon"/>
                  About Us
                </Link>
              }
              <Link to="/resale-deals" className="header__link header__link--pointer">
                <img src="/images/resale-deals.svg" alt="Resale Deals" className="header__link-icon"/>
                Resale Deals
              </Link>
              <Link to="/resale-events" className="header__link header__link--pointer">
                <img src="/images/event.svg" alt="Resale Events" className="header__link-icon"/>
                Events
              </Link>
              { !isAuthenticated &&
                <React.Fragment>
                  <Link
                    to="/auth/sign-up"
                    className={`header__link header__link--register${showSearch ? " header__link--register-search" : ""}`}
                    onClick={() => clearVerifyAccountStatus()}
                  >
                    Register
                  </Link>
                  <Link to="/auth/sign-in" className="header__link" onClick={() => clearAuthError()}>Log In</Link>
                </React.Fragment>
              }
              { isAuthenticated && user &&
                <Dropdown overlay={
                  <Menu>
                    <Menu.Item key="0">
                      <Link to="/profile">
                        <img src="/images/icons/my-profile.svg" alt="My Profile" className="header__user-link-icon"/>
                        <span>My Profile</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      key="1"
                      onClick={() => {
                        signOut();

                        history.push("/");
                      }}
                    >
                      <img src="/images/icons/sign-out.svg" alt="Sign Out" className="header__user-link-icon"/>
                      <span>Sign Out</span>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item key="3">
                      <Link to="/seller/wallet">
                        <img src="/images/icons/wallet.svg" alt="Wallet" className="header__user-link-icon"/>
                        <span>Wallet</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="4">
                      <Link to="/seller/listings/active">
                        <img src="/images/icons/my-listings.svg" alt="My Listings" className="header__user-link-icon"/>
                        <span>My Listings</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="5">
                      <Link to="/profile/purchases/upcoming">
                        <img src="/images/icons/my-purchases.svg" alt="My Purchases" className="header__user-link-icon"/>
                        <span>My Purchases</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="6">
                      <Link to="/selling">
                        <img src="/images/icons/sell-your-booking.svg" alt="Sell Your Booking" className="header__user-link-icon"/>
                        <span>Sell Your Booking</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="7">
                      <Link to="/faq">
                        <img src="/images/icons/faqs.svg" alt="FAQs" className="header__user-link-icon"/>
                        <span>FAQs</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="8">
                      <Link to="/contact-us">
                        <img src="/images/icons/contact-us.svg" alt="Contact Us" className="header__user-link-icon"/>
                        <span>Contact Us</span>
                      </Link>
                    </Menu.Item>
                    { (userRole === ADMIN_ROLE || userRole === SUPER_ADMIN_ROLE) &&
                      <Menu.Divider/>
                    }
                    { (userRole === ADMIN_ROLE || userRole === SUPER_ADMIN_ROLE) &&
                    <Menu.Item key="10">
                      <Link to="/admin/dashboard">
                        <img src="/images/icons/admin-panel.svg" alt="Admin Panel" className="header__user-link-icon"/>
                        <span>Admin Panel</span>
                      </Link>
                    </Menu.Item>
                    }
                  </Menu>
                } placement="bottomRight" trigger={["click"]} className="header__user" overlayClassName="header__user-links">
                  <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <img
                      src="/images/default_avatar.svg"
                      alt={`${user.firstName} ${user.lastName}`}
                      className="header__avatar"
                    />
                    <CaretDownOutlined className="header__caret-down-outlined"/>
                  </div>
                </Dropdown>
              }
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
};

Header.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  userRole: PropTypes.string,
  history: PropTypes.object.isRequired,
  page: PropTypes.string,
  signOut: PropTypes.func.isRequired,
  setNewListing: PropTypes.func.isRequired,
  clearAuthError: PropTypes.func.isRequired,
  clearVerifyAccountStatus: PropTypes.func.isRequired,
};

export default withRouter(connect(
  state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    userRole: state.profile.permissions.role
  }),
  {
    signOut: signOutAction,
    setNewListing: setNewListingData,
    clearVerifyAccountStatus: clearVerifyAccountStatusAction,
    clearAuthError: clearAuthErrorAction
  }
)(Header));
