import React, { useState } from "react";
import useServerSafeEffect from "../../hooks/useServerSafeEffect";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Row, Typography } from "antd";

import ResaleEventItem from "../Listing/ResaleEventItem";

import { getPublicStaticPageRequest } from "../../redux/staticPages/staticPagesActions";

import PartnersList from "../shared/PartnersList";
import SellBooking from "../shared/SellBooking";

import { partners } from "../../static/partners";

import Header from "../shared/Header";
import Footer from "../shared/Footer";

export const ResaleEvents = ({
  getStaticPage,
  pageData,
}) => {

  const [events] = useState([
    { 
      id: 'event-1',
      title: "Manchester Pride",
      startDate: new Date("2023-08-27"),
      endDate: new Date("2023-08-28"),
      detail: "Bank Holiday weekend. Join us for a weekend full of music, parades and much more. Buy and sell your hotel rooms",
      image: "https://www.manchesterpride.com/images/Pride_Logo.svg",
      backgroundColor: "#0D0F21",
      padding: "10px",
      link: "listings?adults=2&children=0&label=Manchester%2C%2520UK&placeId=ChIJ2_UmUkxNekgRqmv-BDgUvtk&placeType=place&rooms=1&initialLat=53.48&initialLon=-2.24"
    },
    { 
      id: 'event-2',
      title: "Yussef Dayrs - Royal Albert Hall",
      startDate: new Date("2023-10-12"),
      endDate: new Date("2023-10-12"),
      detail: "To celebrate the release of his debut studio album Black Classical Music, celebrated multi-instrumentalist and visionary artist Yussef Dayes will perform at the Hall for one night only. Buy and sell your hotel rooms",
      image: "https://d1xqvg77ekojio.cloudfront.net/file/24/sjZw3.jsjJMi5WLsjvGvsjV555k/width=1280/height=720/format=-1/fit=crop/rev=0/Yussef%20Dayes%20Socials%20Images%20twitter.jpg",
      backgroundColor: "#0D4F4D",
      link: "listings?adults=2&children=0&label=South%2520Kensington%2C%2520London%2C%2520UK&placeId=ChIJz7xzwFwFdkgR1fJmrwysxN4&placeType=place&rooms=1&initialLat=51.50&initialLon=-0.12"
    },
    { 
      id: 'event-3',
      title: "London Restaurant Festival",
      startDate: new Date("2023-10-01"),
      endDate: new Date("2023-10-31"),
      detail: "An exclusive programme of events within London Restaurant Festival returns this October. Buy and sell your hotel rooms",
      image: "https://ugc.production.linktr.ee/qSZzrhaRDKwLbjDOjGHA_Qv62q1e9RnzUD9BB",
      backgroundColor: "#FFFFFF",
      link: "listings?adults=2&children=0&label=London%2C%2520UK&placeId=ChIJdd4hrwug2EcRmSrV3Vo6llI&placeType=place&rooms=1&initialLat=51.50&initialLon=-0.12"
    },
    { 
      id: 'event-4',
      title: "London Christmas Markets",
      startDate: new Date("2023-11-28"),
      endDate: new Date("2024-01-05"),
      detail: "Pick up the perfect Christmas gifts for loved ones and enjoy street food, twinkling lights and entertainment throughout the festive season at London's most popular Christmas markets. Buy and sell your hotel rooms",
      image: "https://cdn-lnp.dataweavers.io/-/media/images/london/visit/whats-on/christmas/winter-wonderland/winter-wonderland-christmas-market.jpg?rev=deff8d1c65e14e4eb06b6e6ea8dc448b&mw=640&hash=AEC13727DE9E4AA542AFD5D1BF15DFD4",
      backgroundColor: "#FFFFFF",
      link: "listings?adults=2&children=0&label=London%2C%2520UK&placeId=ChIJdd4hrwug2EcRmSrV3Vo6llI&placeType=place&rooms=1&initialLat=51.50&initialLon=-0.12"
    },
  ]);

  useServerSafeEffect(() => {
    getStaticPage();
  }, []);

  return (
    <div className="main-content resale-deals">
      <Row>
        <Col span={24}>
          <Header/>
          <Row justify="space-around" align="middle" className="listing-list resale-event-list">
            <Col xs={{ span: 24 }} md={{ span: 20 }}>
              <Row>
                <Col xs={{ span: 24 }} className="listing-list__list-container">
                  <Row>
                    <Col span={24} className="listing-list__items">
                      <Row>
                        <Col xs={{ span: 24 }} xl={{ span: 18 }} className="flex-column">
                          <Typography.Title level={1} style={{ "marginBottom": "40px", "fontSize": "36px" }} className="listing-list__title">
                          Check Out our Resale Events Deals
                          </Typography.Title>
                        </Col>
                      </Row>
                      {
                        events.map(event => {
                          return (
                            <div
                              key={`event-${event.id}`}>
                              <ResaleEventItem event={event} />
                            </div>
                          )
                        })
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <section className="resale-deals__section">
                <Row justify="center">
                  <Col xs={{ span: 22 }} lg={{ span: 20 }}>
                    <Typography.Title
                      className="resale-deals__section-title"
                      level={1}
                    >
                      {pageData.partnersTitle}
                    </Typography.Title>
                    <p>{pageData.partnersSubtitle}</p>
                    <PartnersList partners={partners}/>
                  </Col>
                </Row>
              </section>
              <section className="resale-deals__section">
                <Row justify="center">
                  <Col xs={{ span: 22 }} lg={{ span: 20 }}>
                    <SellBooking content={pageData.sellRoomsContent} />
                  </Col>
                </Row>
              </section>
            </Col>
          </Row>
        </Col>
      </Row>
      <Footer/>
    </div>
  )
};

ResaleEvents.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  pageData: PropTypes.any.isRequired,
  getStaticPage: PropTypes.func.isRequired
};

export default connect(
  state => ({
    isProcessing: state.listing.processing,
    pageData: state.staticPage.page,
  }),
  {
    getStaticPage: () => getPublicStaticPageRequest({ permalink: "resale-deals" }),
  }
)(ResaleEvents);
