import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

export const ResaleEventItem = ({ event }) => {

  const imageUrl = event.image ? event.image : "";

  return (
    <Row className="event-item">
      <Col
        xs={{ span: 24 }}
        sm={{ span: 6 }}
        xl={{ span: 7 }}
        className={`event-item__image${!imageUrl ? " event-item__image--bordered" : ""}`}
      >
        { imageUrl
          ? <div
            className="event-item__image"
            style={{ display: "flex", "justifyContent": "center", "maxHeight": "200px", width: "calc(100% - 20px)", margin: "10px", padding: `${event.padding}`, "backgroundColor": `${event.backgroundColor}` }}
          >
            <img style={{ "objectFit": "contain", "maxWidth": "100%" }} src={imageUrl}/>
          </div>
          : <div className="event-item__image">
            <img src="/images/no-image.svg" alt={event.title} className="event-item__empty-hotel-image"/>
          </div>
        }
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 13 }} className="event-item__info">
          
        <div className="event-item__title">
          <span>{event.title || ""}</span>
          <span className="event-item__dates">
                ({ moment(event.startDate).isSame(event.endDate, 'day') ? "" : moment(event.startDate).format("MMM DD") + ' - '}{moment(event.endDate).format("MMM DD")})
          </span>
        </div>
        <div className="event-item__deetail">{event.detail || ""}</div>
      </Col>
      <Col xs={{ span: 20, offset: 2 }} sm={{ span: 6, offset: 0  }} xl={{ span: 4, offset: 0 }} >
        <Link
          className="event-item__btn"
          to={`${event.link}`}
        >
            Search Deals
        </Link>
      </Col>
    </Row>
  );
};

ResaleEventItem.propTypes = {
  event: PropTypes.object.isRequired
};

export default withRouter(ResaleEventItem);
